<template>
  <div class="flex items-center">
    <router-link :to="url" @click.stop.prevent class="text-primary">{{ params.value }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  computed: {
    encrypt() {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);
    },

    url () {
      return { name: 'todo-list-show',  params: { id: this.encrypt } };
    }
  }
}
</script>
