<template>

  <div id="page-user-list">

    <div class="vx-card p-6" v-show="loaded">

      <!-- Header -->
      <div class="flex justify-between flex-wrap items-center">
        <!-- Search Form -->
        <vs-input class="sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />

        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full">
            <!-- Date choices -->
            <datepicker format="d/MM/yyyy" :placeholder="$i18n.t('Date')" :value="date" @input="date = $moment($event)"></datepicker>
          </div>

          <div class="vx-col sm:w-1/2 w-full">
            <!-- Button Add -->
            <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4" @click="addItem">Add Item</vs-button>
          </div>
        </div>
      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <!-- Pagination -->
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full">
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ itemsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : itemsData.length }} of {{ itemsData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <!-- PAGINATION -->
          <vs-pagination
            :total="totalPages"
            :max="7"
            v-model="currentPage" />
        </div>
      </div>

    </div>

    <!--  Popup  -->
    <vs-popup classContent="popup-example" :title="`${mode} item`" :active.sync="popup">

      <div class="vx-row mb-3">
        <div class="vx-col md:w-1/2 w-full">
          <small class="mb-1">{{ $i18n.t('Date') }}</small>
          <datepicker format="d/MM/yyyy" disabled="" :value="date" @input="date = $moment($event)"></datepicker>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <small class="mb-1">{{ $i18n.t('Time') }}</small>
          <flat-pickr :config="configdateTimePicker" disabled="" v-model="form.time" :placeholder="`00:00`" style="width: 100%" />
        </div>
      </div>

      <div class="vx-row mb-3">
        <div class="vx-col md:w-1/2 w-full">
          <small class="mb-1">{{ $i18n.t('StartTime') }}</small>
          <flat-pickr :config="configdateTimePicker" v-model="form.start_time" :placeholder="`00:00`" style="width: 100%" />
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <small class="mb-1">{{ $i18n.t('EndTime') }}</small>
          <flat-pickr :config="configdateTimePicker" v-model="form.end_time" :placeholder="`00:00`" style="width: 100%" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <small class="mb-1">{{ $i18n.t('TodoList') }}</small>
          <vs-textarea class="w-full mb-3" rows="6" v-model="form.todo_list" />
        </div>
      </div>

      <div class="mt-5">
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
          {{ $i18n.t('Save') }}
        </vs-button>
      </div>

    </vs-popup>

  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'
import Datepicker from "vuejs-datepicker";
import axios from "@/axios";

export default {
  components: {
    AgGridVue,
    vSelect,
    Datepicker,
    flatPickr,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile
  },
  watch: {
    date () {
      this.getTodoLists();
    },
    popup () {
      if (!this.popup) {
        this.form = {
          date: moment(new Date()).format('YYYY-MM-DD'),
          time: moment(new Date()).format('hh:mm:ss'),
        };
      }
    }
  },
  data () {
    return {
      loaded: false,
      searchQuery: '',
      date: moment(new Date()).format('YYYY-MM-DD'),

      form: {
        date: moment(new Date()).format('YYYY-MM-DD'),
        time: moment(new Date()).format('hh:mm:ss'),
      },
      popup: false,
      mode: 'add',
      itemSelected: false,

      configdateTimePicker: {
        enableTime: true,
        enableSeconds: true,
        noCalendar: true,
        time_24hr: true,
        defaultHour: 8,
        minuteIncrement: 1
      },

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Date'),
          field: 'date',
          filter: true,
          headerClass: 'text-center',
          cellClass: 'text-center'
        },
        {
          headerName: this.$i18n.t('Start'),
          field: 'start',
          filter: true,
        },
        {
          headerName: this.$i18n.t('End'),
          field: 'end',
          filter: true,
        },
        {
          headerName: this.$i18n.t('TodoList'),
          field: 'todo_list',
          filter: true,
          cellRendererFramework: 'CellRendererLink',
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          headerClass: 'text-center',
          cellClass: 'text-center',
          cellRendererParams: {
            editItem: this.editItem.bind(this),
            deleteItem: this.deleteItem.bind(this),
          },
        }
      ],
    }
  },
  computed: {
    itemsData () {
      return this.$store.state.todolist.items
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    getTodoLists () {
      this.$store.dispatch('todolist/fetchItems', { date: this.date }).then(res => {
        console.log(res);
      }).catch(err => {

        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
      .finally(() => {
        this.onLoaded();
      })
    },
    addItem () {
      this.popup = true;
      this.mode = 'add';
    },
    editItem (item) {
      this.popup = true;
      this.mode = 'edit';
      this.itemSelected = item;

      this.form = {
        start_time: item.start,
        end_time: item.end,
        todo_list: item.todo_list,
      };
      console.log(this.form);
    },
    deleteItem (item) {
      axios.delete('/todo-lists/' + item.id).then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.getTodoLists();
        });
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },
    storeItem () {
      if (this.mode == 'add') {
        let body = {
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          todo_list: this.form.todo_list,
          date: this.date,
        };

        axios.post('/todo-lists', body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getTodoLists();
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
      else if (this.mode == 'edit') {
        let body = {
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          todo_list: this.form.todo_list,
          _method: 'PUT',
        };

        axios.post('/todo-lists/' + this.itemSelected.id, body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getTodoLists();
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }

      this.mode = false;
    },

    onLoaded(){
      this.loaded = true;
      this.$vs.loading.close();
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    this.gridApi.sizeColumnsToFit();
  },
  created () {
    this.loading();
    this.getTodoLists();
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
